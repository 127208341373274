import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

import HomeSlideshow from "../components/homeSlideshow"
import HomeNav from "../components/homeNav"

const IndexPage = ({ data }) => (
  <Layout home={true}>
    <SEO title="Home" keywords={[`gatsby`, `application`, `react`]} />
    <HomeSlideshow images={data.wordpressPage.acf.home_images} />
    <HomeNav projects={data.allWordpressWpProjects.edges} />
  </Layout>
)

export default IndexPage

export const query = graphql`
  query {
    allWordpressWpProjects(
      filter: { wordpress_parent: { eq: 0 } }
      sort: { fields: [menu_order] }
    ) {
      edges {
        node {
          wordpress_id
          slug
          title
          wordpress_parent
          project_children {
            post_title
            post_name
          }
        }
      }
    }
    wordpressPage(slug: { eq: "home" }) {
      acf {
        home_images {
          localFile {
            childImageSharp {
              fluid(maxWidth: 2400) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }
  }
`
