import React from "react"
import styled from "styled-components"
import Img from "gatsby-image"
import Slider from "react-slick"
import "slick-carousel/slick/slick.css"

const HomeSlider = styled.div`
  width: 100%;
  height: 100vh;
  position: absolute;
  overflow: hidden;
  top: 0;
  left: 0;
  z-index: 0;
  background: black;
  overflow: hidden;
  .slick-slider {
    opacity: 0.7;
  }
`

const HomeSlideshow = props => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 1000,
    fade: true,
    autoplay: true,
    arrows: false,
    pauseOnHover: false,
  }
  return (
    <HomeSlider>
      <Slider {...settings}>
        {props.images.map(e => {
          return (
            <div>
              <Img
                style={{ width: "100%", height: "100vh" }}
                imgStyle={{ width: "100%", height: "100%", objectFit: "cover" }}
                fluid={e.localFile.childImageSharp.fluid}
              />
            </div>
          )
        })}
      </Slider>
    </HomeSlider>
  )
}

export default HomeSlideshow
