import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import { Textfit } from "react-textfit"

const HomeNavWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 99.9vh;
  z-index: 100;
  color: white;
  position: relative;
`

const MenuContainer = styled.div`
  width: calc(100% - 8rem);
  height: calc(100vh - 10rem);
  padding-top: 1rem;
  margin: 0 auto;
  text-align: justify;
  line-height: 1.5;
  /* text-align: justify; */
  /* word-spacing: -0.1em; */
  a {
    display: inline;
    vertical-align: middle;
    text-transform: uppercase;
    letter-spacing: 0.2rem;
    color: white;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }

  @media only screen and (max-width: 500px) {
    width: calc(100% - 3rem);
    height: calc(100vh - 3rem);
    padding-top: 0.5rem;
  }
`
const Bullet = styled.div`
  display: inline-block;
  vertical-align: middle;
  width: 10px;
  height: 10px;
  margin: 0 1rem;
  background: white;
  border-radius: 100%;
  @media only screen and (max-width: 500px) {
    width: 5px;
    height: 5px;
  }
`

const HomeNav = ({ projects }) => {
  return (
    <HomeNavWrapper>
      <Textfit mode="multi">
        <MenuContainer>
          {projects.map((e, i) => {
            const slug = e.node.slug
            let length = 0
            if (e.node.project_children) {
              length = e.node.project_children.length
            }

            return (
              <span key={i}>
                {i > 0 && <Bullet></Bullet>}
                <Link
                  key={i}
                  to={`/${e.node.slug}/`}
                  dangerouslySetInnerHTML={{ __html: e.node.title }}
                ></Link>
                {e.node.project_children &&
                  e.node.project_children.map((e, i) => {
                    return (
                      <span key={i}>
                        {i !== length && <Bullet></Bullet>}
                        <Link
                          key={i}
                          style={{ fontSize: "75%" }}
                          to={`/${slug}/${e.post_name}/`}
                        >
                          {e.post_title}
                        </Link>
                      </span>
                    )
                  })}
              </span>
            )
          })}
          <span>
            <Bullet></Bullet>
            <Link to="/about" style={{ fontWeight: "bold" }}>
              Marco Rios
            </Link>
          </span>
        </MenuContainer>
      </Textfit>
    </HomeNavWrapper>
  )
}

export default HomeNav
